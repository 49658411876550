import React from 'react'
import Toggle from '@veneer/core/dist/scripts/toggle'
import Button from '@veneer/core/dist/scripts/button'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// import { LogoHpPlus } from '@veneer/core/dist/scripts/logos'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import WarningMarker from '../WarningMarker'
import { SecurityAssessmentStatus } from '../../static/consts'
import EmptyLeftItem from '../EmptyLeftItem'
import { IconCloud, List, ThemeProvider } from '@veneer/core'

const Web = ({
  active,
  strings,
  isEnabled,
  isAssessmentInProgress,
  state,
  onToggleMonitoring,
  showSecurityModal,
  dismissSecurityModal,
  isHPX
}) => {
  const rightItem = () => {
    if (state.isMonitoringInProgress || isAssessmentInProgress) {
      return <ProgressIndicator data-testid="ss-progress-indicator" />
    }
    if (active === SecurityAssessmentStatus.trueValue) {
      return (
        <Toggle
          on={isEnabled}
          disabled={state.isMonitoringInProgress}
          onChange={showSecurityModal}
          id="toggle"
          data-testid="ss-monitor-toggle"
        />
      )
    }
    return <></>
  }

  return (
    <>
      <Modal
        id="ss-modal"
        align="center"
        onClose={dismissSecurityModal}
        show={state.isShowingModal}
        title={strings.modalTitle}
        data-testid="ss-monitorModal"
        footer={
          <div className="vn-button-group--responsive">
            <Button
              // style={{
              //   margin: '10px'
              // }}
              onClick={isEnabled ? dismissSecurityModal : onToggleMonitoring}
              data-testid="ss-monitorModal-security-button"
            >
              {strings.buttonPrimaryTitle}
            </Button>
            <Button
              appearance="secondary"
              onClick={isEnabled ? onToggleMonitoring : dismissSecurityModal}
              data-testid="ss-monitorModal-removeSecurity-button"
            >
              {strings.buttonSecondaryTitle}
            </Button>
          </div>
        }
      >
        <p>{strings.modalDescription}</p>
      </Modal>
      {!isHPX ? (
        <CardItem
          customCss={{ padding: '20px' }}
          leftItem={
            <>
              <EmptyLeftItem />
              <WarningMarker isSecured={isEnabled} />
            </>
          }
          contentItem={
            <CardItemContent
              title={strings.title}
              titleStyle={{ marginLeft: '14px', fontSize: '24px' }}
              customCss={{ marginLeft: '14px' }}
              content={<>{strings.description}</>}
            />
          }
          rightItem={rightItem()}
        />
      ) : (
        <div
          style={{
            padding: '0px 12px 0px 12px',
            backgroundColor: '#ffffff',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px'
          }}
        >
          <List>
            <ThemeProvider shape="sharp">
              <CardItem
                customCss={{
                  padding: '24px 12px 24px 12px',
                  borderRadius: '0',
                  borderTop: '0px'
                }}
                leftItem={
                  <IconCloud
                    size={24}
                    color={isEnabled ? '#00834B' : '#191A22'}
                  />
                }
                contentItem={
                  <CardItemContent
                    customCss={{ fontSize: '20px', fontWeight: '500' }}
                    // isSubcontent
                    title={strings.title}
                  />
                }
              />
              <List>
                <List
                  style={{
                    borderTop: '1px solid #EDEEFE',
                    width: 'auto'
                  }}
                ></List>
                <CardItem
                  customCss={{
                    border: '0px',
                    padding: '24px 12px 24px 12px'
                  }}
                  contentItem={
                    <CardItemContent
                      customCss={{
                        fontSize: '18px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#292A35'
                      }}
                      content={<>{strings.description}</>}
                    />
                  }
                  rightItem={rightItem()}
                />
              </List>
              <List>
                <CardItem
                  customCss={{ border: '0px', padding: '16px 12px 16px 12px' }}
                  contentItem={
                    <CardItemContent
                      isSubcontent
                      customCss={{
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#525365'
                      }}
                      content={
                        <>
                          Sync your security assessment to HP Cloud for remote
                          access. Information is exclusively shared with you,
                          ensuring no personal data is stored in the cloud.
                        </>
                      }
                    />
                  }
                />
              </List>
            </ThemeProvider>
          </List>
        </div>
      )}
    </>
  )
}

export default Web

Web.propTypes = {
  active: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isAssessmentInProgress: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  onToggleMonitoring: PropTypes.func.isRequired,
  showSecurityModal: PropTypes.func.isRequired,
  dismissSecurityModal: PropTypes.func.isRequired
}
