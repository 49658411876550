import React, { useContext } from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import CardItem from './CardItem'
import CardItemContent from './CardItemContent'
import AuthContext from '../contexts/AuthContext'

const UpdateInProgressCard = () => {
  const { i18n, isMobileSize } = useContext(AuthContext)
  const leftItem = () => {
    return <ProgressIndicator value={24} data-testid="ss-progress-indicator" />
  }
  const contentItem = () => {
    return (
      <CardItemContent
        title={i18n.t('shared.titleInProgress')}
        titleStyle={{ fontSize: '24px' }}
      />
    )
  }

  return (
    <CardItem
      leftItem={leftItem()}
      contentItem={contentItem()}
      customCss={{ padding: '30px' }}
      hasShadow={isMobileSize}
    />
  )
}

export default UpdateInProgressCard
