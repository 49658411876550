import React from 'react'
import { Password, ThemeProvider } from '@veneer/core'
import { Button } from '@veneer/core'
// import PropTypes from 'prop-types';
import CardItem from '../CardItem'
import WarningMarker from '../WarningMarker'
import CardItemContent from '../CardItemContent'
import AuthContext from '../../contexts/AuthContext'

interface PasswordFormProps {
  useSetAdminPassword: any
  isMobileSize?: boolean
}
const PasswordForm = ({
  useSetAdminPassword,
  isMobileSize
}: PasswordFormProps) => {
  const { isHPX } = React.useContext(AuthContext)
  const defaultPadding = {
    paddingTop: '10px'
  }
  const {
    isPwdUnknownValue,
    isInProgress,
    confirmationPasswordMatchMessage,
    passwordMeetingRequirementsMessage,
    configAdminPasswordSetStrings,
    password,
    isPasswordCompliantWithRequirements,
    confirmationPassword,
    onInputPassword,
    onInputConfirmationPassword,
    verifyPassword,
    isLoading,
    isCompliant,
    printerID,
    clearPwdMsg,
    clearPwdConfirmMsg,
    verifyPasswordInput,
    verifyConfirmationPwdInput,
    isConfirmPwdCompliant
  } = useSetAdminPassword

  const verifyInputs = () => {
    verifyPasswordInput()
    verifyConfirmationPwdInput()
  }

  const contentItemCard = (
    <CardItemContent
      customCss={{
        ...defaultPadding,
        ...(isMobileSize ? { whiteSpace: 'normal' } : {}),
        paddingBottom: isHPX ? '24px' : ''
      }}
      isSubcontent
      title={configAdminPasswordSetStrings.title2}
      content={
        <div
          style={{
            color: isHPX ? '#525365' : '',
            fontWeight: isHPX ? '400' : '',
            fontSize: isHPX ? '14px' : '',
            lineHeight: isHPX ? '20px' : ''
          }}
        >
          {configAdminPasswordSetStrings.recommendation}
        </div>
      }
    >
      <Password
        value={password}
        onChange={onInputPassword}
        onFocus={() => {
          clearPwdMsg()
          verifyConfirmationPwdInput()
        }}
        maxLength={16}
        onBlur={verifyInputs}
        error={!isPasswordCompliantWithRequirements}
        disabled={isCompliant}
        helperText={passwordMeetingRequirementsMessage}
        css={{
          ...defaultPadding,
          maxWidth: '380px',
          padding: isHPX ? '16px 24px 8px 0px' : ''
        }}
        label={
          !isHPX
            ? configAdminPasswordSetStrings.passwordField
            : configAdminPasswordSetStrings.passwordHPXField
        }
        name="password"
        id={`password${printerID}`}
        data-testid="ss-password"
      />
      <Password
        value={confirmationPassword}
        onFocus={() => {
          clearPwdConfirmMsg()
          verifyPasswordInput()
        }}
        onBlur={verifyInputs}
        maxLength={16}
        onChange={onInputConfirmationPassword}
        error={!isConfirmPwdCompliant}
        helperText={confirmationPasswordMatchMessage}
        disabled={isCompliant}
        css={{
          ...defaultPadding,
          maxWidth: '380px',
          paddingBottom: '20px',
          padding: isHPX ? '8px 24px 10px 0px' : ''
        }}
        label={
          !isHPX
            ? configAdminPasswordSetStrings.confirmationPasswordField
            : configAdminPasswordSetStrings.confirmationHPXPasswordField
        }
        name="password-confirmation"
        id={`password-confirmation${printerID}`}
        data-testid="ss-password-confirmation"
      />
      <div
        className={isMobileSize ? '' : 'line-break'}
        data-testid="ss-printer-password-hint"
        style={{
          fontSize: isHPX ? '14px' : '',
          color: isHPX ? '#525365' : '',
          fontWeight: isHPX ? '400' : '',
          lineHeight: isHPX ? '20px' : ''
        }}
      >
        {configAdminPasswordSetStrings.recommendation2}
      </div>
      {isHPX ? (
        <ThemeProvider shape="pill">
          <Button
            style={{
              width: isMobileSize ? '100%' : '380px',
              position: 'relative',
              boxSizing: 'border-box'
            }}
            appearance="primary"
            colorScheme="default"
            disabled={isCompliant}
            customStyle={{ marginTop: '20px' }}
            onClick={verifyPassword}
            loading={isLoading}
            data-testid="ss-setPassword-button"
          >
            {configAdminPasswordSetStrings.title2}
          </Button>
        </ThemeProvider>
      ) : (
        <Button
          appearance="primary"
          colorScheme="default"
          disabled={isCompliant}
          customStyle={{ marginTop: '20px' }}
          onClick={verifyPassword}
          loading={isLoading}
          data-testid="ss-setPassword-button"
        >
          {configAdminPasswordSetStrings.title2}
        </Button>
      )}
    </CardItemContent>
  )

  return (
    <CardItem
      customCss={{
        borderRadius: isHPX ? '0px' : '',
        paddingLeft: isHPX ? '0px' : '',
        paddingTop: isHPX ? '24px' : '',
        border: isHPX ? '0px' : '',
        paddingBottom: isHPX ? '24px' : ''
      }}
      leftItem={
        isMobileSize ? undefined : !isHPX ? (
          <WarningMarker
            isSecured={isCompliant || isInProgress || isPwdUnknownValue}
          />
        ) : (
          <></>
        )
      }
      contentItem={
        isMobileSize ? (
          contentItemCard
        ) : (
          <div
            style={{
              marginLeft: !isHPX ? '38px' : '4px'
            }}
          >
            {contentItemCard}
          </div>
        )
      }
    />
  )
}

export default PasswordForm

// PasswordForm.propTypes = {
//   useSetAdminPassword: PropTypes.object.isRequired,
//   isMobileSize: PropTypes.bool,
// };

// PasswordForm.defaultProps = {
//   isMobileSize: false,
// };
