import React from 'react'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PasswordForm from './PasswordForm'
import { ThemeProvider } from '@veneer/theme'
import { List } from '@veneer/core'

const Web = ({
  strings,
  setAdminPassword,
  isCompliant,
  isInProgress,
  loadingCard,
  isPwdUnknownValue,
  isHPX
}) => {
  const regularContent = !isHPX ? (
    <li className="ss-password-section">
      <CardItem
        customCss={{ padding: '20px' }}
        leftItem={
          <div className="list-item__left-section">
            <StatefulIcon value={isCompliant} isHPX={isHPX} />
          </div>
        }
        contentItem={
          <CardItemContent
            titleStyle={{ fontSize: '24px' }}
            title={strings.title}
            content={<>{strings.description}</>}
          />
        }
      />

      {!isCompliant && !isPwdUnknownValue ? (
        <PasswordForm useSetAdminPassword={setAdminPassword} />
      ) : (
        ''
      )}
    </li>
  ) : (
    <li
      className="ss-password-section"
      style={{ borderBottom: '1px solid #D1D2E4' }}
    >
      <ThemeProvider shape="sharp">
        <List>
          <CardItem
            customCss={{
              padding: isHPX ? '24px 24px 24px 24px' : '',
              border: '0px',
              lineHeight: '28px'
            }}
            leftItem={
              <div className="list-item__left-section">
                <StatefulIcon value={isCompliant} isHPX={isHPX} />
              </div>
            }
            contentItem={
              <CardItemContent
                title={strings.title}
                content={<>{strings.description}</>}
              />
            }
          />
        </List>
      </ThemeProvider>
      <div
        style={{
          padding: '0px 12px 0px 12px',
          backgroundColor: '#ffffff'
        }}
      >
        {!isCompliant && !isPwdUnknownValue ? (
          <List
            style={{
              width: 'auto',
              borderTop: '1px solid #EDEEFE'
            }}
          ></List>
        ) : (
          ''
        )}
        {!isCompliant && !isPwdUnknownValue ? (
          <PasswordForm useSetAdminPassword={setAdminPassword} />
        ) : (
          ''
        )}
      </div>
    </li>
  )

  return isInProgress ? loadingCard : regularContent
}

export default Web
